/**
 * 订单相关
 */
/* eslint-disable no-unused-vars */
import request from "@/base/index";
import { hospital_id } from "@/appConfig.js";

export default {
  namespaced: true,
  state: {
    list: [],
    listOption: {
      page: 1,
      pagesize: 10,
    },
    finished: false,
    loading: false,
  },
  getters: {},
  mutations: {
    saveList(state, payload) {
      state.list = payload.data;
      state.finished = payload.finished;
    },
    resetOption(state, payload) {
      state.listOption = {
        page: 1,
        pagesize: 10,
      };
      state.finished = false;
    },
    resetData(state) {
      state.listOption = {
        page: 1,
        pagesize: 10,
      };
      state.list = [];
      state.finished = false;
      state.loading = false;
    },
    addPage(state) {
      state.listOption.page += 1;
    },
    changeLoading(state, payload) {
      state.loading = payload;
    },
  },
  actions: {
    getDoctor({ state, dispatch }, id) {
      // id = id.split("_")[1];
      return request
        .post(
          "api/guangzhou-pat/consult/detail",
          {
            id,
          },
          true
        )
        .then((res) => {
          dispatch(
            "doctor/getDoctorInfo",
            { doctor_id: res.data.doctor_id },
            { root: true }
          );
        });
    },
    getList(context, payload) {
      const { listOption, list } = context.state;
      return request
        .post(
          "api/guangzhou-pat/consult/list",
          {
            user_id: payload.user_id,
            hospital_id,
            ...listOption,
          },
          true
        )
        .then(({ code, msg, data }) => {
          if (code == 200) {
            const finished = data.length !== listOption.pagesize;
            const appendData = Array.isArray(data) ? data : []; //如果下一页没有更多数据时返回data为false
            console.log(data, ">>>>>>>>>>>>>>>>>>");
            context.commit("saveList", {
              data: payload.isLoadMore ? list.concat(appendData) : data,
              finished,
            });
          } else {
            context.commit("showMessage", msg, {
              root: true,
            });
          }
          return {
            code,
          };
        })
        .finally(() => {
          if (payload.isLoadMore) {
            context.commit("changeLoading", false);
          }
        });
    },

    refreshList(context, payload) {
      context.commit("resetOption");
      context.dispatch("getList", {
        user_id: payload.user_id,
      });
    },
    resetData(context) {
      context.commit("resetData");
    },
    loadMore(context, payload) {
      if (!context.state.finished) {
        context.commit("addPage");
        context.dispatch("getList", {
          user_id: payload.user_id,
          isLoadMore: true,
        });
      }
    },
    changeLoading(context, payload) {
      context.commit("changeLoading", payload);
    },
  },
};
