/** 加载兼容的 js 文件 */
import { addScriptToHead } from "@/utils/loadScript";
import { PromisejsUrl } from "@/cdnUrlConfig";

export function addPolyfill(onloadFn, onErrorFn) {
  //包里没有一些腻子脚本---------------只有少数机型是需要加载的；
  if (!window.Promise) {
    addScriptToHead(PromisejsUrl, onloadFn, onErrorFn);
  } else {
    onloadFn();
  }
}
