/**
 * 问诊人
 */
/* eslint-disable no-unused-vars */
import request from "@/base/index";
import { hospital_id } from "@/appConfig.js";
export default {
  namespaced: true,
  state: {
    interrogationList: [],
    pitchOn: JSON.parse(localStorage.getItem("pitchOn")) || {},
    noticeChecked: localStorage.getItem("noticeChecked") == 1 ? true : false, //医保复诊知情同意书
  },
  mutations: {
    saveInterrogationList(state, payload) {
      state.interrogationList = payload;
    },
    //设置 departmentVal、jobVal、sortVal
    savePitchOn(state, payload) {
      state.pitchOn = payload;
      localStorage.setItem("pitchOn", JSON.stringify(payload));
    },
    delPitchOn(state, payload) {
      state.pitchOn = {};
      localStorage.setItem("pitchOn", JSON.stringify({}));
    },
    delInterrogation(state, payload) {
      state.interrogationList = state.interrogationList.filter((item) => {
        return item.id != payload.id;
      });
    },
    saveNoticeChecked(state, payload) {
      state.noticeChecked = payload;
      localStorage.setItem("noticeChecked", payload ? 1 : 0);
    },
  },
  actions: {
    getInterrogationList(context, payload) {
      const loginkey = payload.loginkey || "";
      if (!loginkey) return;
      return request
        .get(
          "api/guangzhou-pat/person/interrogation-list",
          { user_access_token: loginkey, hospital_id },
          true
        )
        .then(({ code, data, msg }) => {
          if (200 == code) {
            context.commit("saveInterrogationList", data.list || []);
            if (!context.state.pitchOn.id) {
              context.commit("savePitchOn", data.list ? data.list[0] : {});
            }
          } else {
            context.commit("showMessage", msg, { root: true });
          }
        });
    },
    addInterrogation(context, payload) {
      let obj = {};
      //编辑时使用
      if (payload.id) {
        obj.id = payload.id;
      }
      const guarder_name = payload.guarder_name;
      const guarder_card = payload.guarder_card;
      const guarder_tel = payload.guarder_tel;
      if (guarder_name) {
        obj.guarder_name = guarder_name;
      }
      if (guarder_card) {
        obj.guarder_card = guarder_card;
      }
      if (guarder_tel) {
        obj.guarder_tel = guarder_tel;
      }
      const param = Object.assign(
        obj,
        {
          realname: payload.realname,
          id_card: payload.id_card,
          sex: payload.sex,
          tel: payload.tel,
          relationship: payload.relationship,
          healthy: JSON.stringify(payload.healthy),
          user_access_token: payload.user_access_token,
        },
        { hospital_id }
      );
      console.log("param", param);
      //编辑成功后修改本地数据
      return request
        .post("api/guangzhou-pat/person/interrogation-edit", param, true)
        .then(({ code, data, msg }) => {
          console.log("code, data, msg", code, data, msg);
          return {
            flag: code == 200,
            code,
            data,
            msg,
          };
          // if (200 == code) {
          //   context.commit("saveInterrogationList", data);
          // } else {
          //   context.commit("showMessage", msg, { root: true });
          // }
        });
    },
    delInterrogation(context, payload) {
      //编辑成功后修改本地数据
      return request
        .get(
          "api/guangzhou-pat/person/interrogation-del",
          Object.assign(payload, {
            hospital_id,
          }),
          true
        )
        .then(({ code, data, msg }) => {
          console.log("code, data, msg", code, data, msg);
          if (200 == code) {
            context.commit("delInterrogation", payload);
          }
          return {
            code,
            data,
            msg,
          };
        });
    },
    delPitchOn(context) {
      context.commit("delPitchOn");
    },
    saveNoticeChecked(context, payload) {
      context.commit("saveNoticeChecked", payload);
    },
  },
  modules: {},
};
