/* eslint-disable prettier/prettier */
import axios from "@/base/axios";
import { getBaseUrl } from "@/base/baseUrl";
import { aesEncrypt, aesDecrypt } from "@/base/AES";
let instance = axios();
import { getRandomStr } from "@/utils/string/getRandomStr";

const kpAppid = {
  appid: "3000000002",
  appkey: "Gexj2V%!72UlBGmW@lcXK@%GT0Hr5vMc",
  time: Date.parse(new Date()), //当前时间戳
  version: "5.7.6",
  os: "h5",
  noncestr: getRandomStr(6),
};

export default {
  /** *
   * defUrl: 请求地址
   * params: 请求参数
   * flag：是否加密,————默认加密
   */
  get(defUrl, params) {
    const url = getBaseUrl(defUrl);
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async function (resolve) {
      const options = {};
      var _params = encodeURIComponent(
        await aesEncrypt(JSON.stringify(params), kpAppid.appkey)
      );
      options.params = Object.assign(kpAppid, { data: _params });
      resolve(options);
    }).then((options) => {
      return instance.get(url, options).then(async (res) => {
        const { code, data, msg } = res.data;
        const resData = await aesDecrypt(data, kpAppid.appkey);
        let resDataObj = null;
        try {
          resDataObj = JSON.parse(resData);
        } catch (e) {
          resDataObj = null;
        }
        return {
          code,
          data: resDataObj,
          msg,
        };
      });
    });
  },
  /** *
   * defUrl: 请求地址
   * params: 请求参数
   * flag：是否加密,————默认加密
   */
  post(defUrl, params = {}) {
    const url = getBaseUrl(defUrl);
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async function (resolve) {
      var _params = encodeURIComponent(
        await aesEncrypt(JSON.stringify(params), kpAppid.appkey)
      );
      resolve(Object.assign(kpAppid, { data: _params }));
    }).then((params) => {
      return instance.post(url, params).then(async (res) => {
        const { code, data, msg } = res.data;
        const resData = await aesDecrypt(data, kpAppid.appkey);
        let resDataObj = null;
        try {
          resDataObj = JSON.parse(resData);
        } catch (e) {
          resDataObj = null;
        }
        return {
          code,
          data: resDataObj,
          msg,
        };
      });
    });
  },
};
