// 获取所有的key
export function getStrs(data) {
  var strStr = data.slice(1);
  strStr = strStr.split("?")[1] || strStr.split("?")[0];
  strStr = strStr.split("&");
  var obj = {};
  for (var i = 0; i < strStr.length; i++) {
    var newStr = strStr[i].split("=");
    obj[newStr[0]] = newStr[1];
  }
  return obj;
}
