/**
 * 医院相关的信息
 */
import request from "@/base/index";
import { hospital_id } from "@/appConfig.js";

export default {
  namespaced: true,
  state: {
    departmentList: [],
    hotdeplist: [],
    titleList: [],
    sortList: [
      {
        id: 0,
        name: "默认排序",
      },
      {
        id: 1,
        name: "价格由低到高",
      },
    ],
    departmentVal: 0,
    jobVal: 0,
    sortVal: 0,
  },
  mutations: {
    saveDepartmentList(state, payload) {
      state.departmentList = [
        { first_id: 0, first_name: "全部科室" },
        ...payload,
      ];
    },
    saveHotDepList(state, payload) {
      state.hotdeplist = [...payload];
    },
    saveTitleList(state, payload) {
      state.titleList = [{ code: 0, name: "不限制" }, ...payload.list];
    },
    //设置 departmentVal、jobVal、sortVal
    setState(state, payload) {
      let { key, value } = payload;
      if (value < 0) value = 0;
      state[key] = value;
    },
    resetFilterItem(state) {
      state.departmentVal = 0;
      state.jobVal = 0;
      state.sortVal = 0;
    },
  },
  actions: {
    //获取全部科室列表-根据type判断是热门还是全部科室 1全部科室 3热门科室，kid：一级科室id
    getDepartmentList(context) {
      const params = { common_hospital_code: hospital_id, type: 1, kid: 0 };
      return request
        .post(
          `${process.env.VUE_APP_NETWORK}/public/api/department/search`,
          params,
          true
        )
        .then(({ code, data, msg }) => {
          if (200 == code) {
            console.log(data, "全部科室列表");
            context.commit("saveDepartmentList", data);
          } else {
            context.commit("showMessage", msg, { root: true });
          }
        });
    },
    // 获取热门科室列表-根据type判断是热门还是全部科室 1全部科室 3热门科室，kid：一级科室id
    getHotDepartmentList(context) {
      const params = { common_hospital_code: hospital_id, type: 3, kid: 0 };
      return request
        .post(
          `${process.env.VUE_APP_NETWORK}/public/api/department/search`,
          params,
          true
        )
        .then(({ code, data, msg }) => {
          if (200 == code) {
            console.log(data, "热门科室列表");
            context.commit("saveHotDepList", data);
          } else {
            context.commit("showMessage", msg, { root: true });
          }
        });
    },
    // 获取职称列表
    getTitleList(context) {
      const params = {
        common_hospital_code: hospital_id,
        page: 1,
        pageSize: 10,
      };
      return request
        .post(
          `${process.env.VUE_APP_NETWORK}/public/api/title/list`,
          params,
          true
        )
        .then(({ code, data, msg }) => {
          if (200 == code) {
            console.log(data, "职称列表");
            context.commit("saveTitleList", data);
          } else {
            context.commit("showMessage", msg, { root: true });
          }
        });
    },
    resetFilterItem({ commit }) {
      commit("resetFilterItem");
    },
  },
  modules: {},
};
