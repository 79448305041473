<template>
  <div id="pageRef">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" :key="key" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" :key="key" />
  </div>
</template>
<script>
import "@/assets/css/index.scss";
import { getLoginkey } from "@/utils/userInfo/loginkey";
import { mapGetters } from "vuex";
// eslint-disable-next-line no-unused-vars
const pageRef = document.getElementById("pageRef");
export default {
  //在此处恢复登录信息
  created() {
    let loginkey = getLoginkey();
    //7天内的登录信息
    let userInfo = localStorage.getItem(loginkey);
    if (userInfo && loginkey) {
      try {
        this.$store.commit("user/echoLoginInfoData", JSON.parse(userInfo));
      } catch (e) {
        console.log("eeee");
      }
    } else {
      localStorage.clear();
    }
  },
  computed: {
    ...mapGetters(["isSDKReady", "timIsLogin"]),
    groupId() {
      return this.$route.params.groupId;
    },
    key() {
      return this.$route.fullPath;
    },
  },
  beforeDestroy() {
    if (this.isSDKReady && this.timIsLogin) {
      this.$store.dispatch("logout");
    }
  },
};
</script>
<style lang="scss">
.van-dialog_consent {
  .van-dialog__message {
    font-size: 17px;
  }
}
.chat_wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}
</style>
