// import cryptoJs from "crypto-js";
import { loadScript } from "@/utils/loadScript";
import { CryptoJSUrl } from "@/cdnUrlConfig";

//DES加密
export async function encryptDes(message, key) {
  if (!window.CryptoJS) await loadScript(CryptoJSUrl);
  let cryptoJs = window.CryptoJS;
  var keyHex = cryptoJs.enc.Utf8.parse(key);
  var option = { mode: cryptoJs.mode.ECB, padding: cryptoJs.pad.Pkcs7 };
  var encrypted = cryptoJs.DES.encrypt(message, keyHex, option);
  return encrypted.ciphertext.toString();
}

//DES解密
export async function decryptDes(message, key) {
  if (!window.CryptoJS) await loadScript(CryptoJSUrl);
  let cryptoJs = window.CryptoJS;
  var keyHex = cryptoJs.enc.Utf8.parse(key);
  var decrypted = cryptoJs.DES.decrypt(
    {
      ciphertext: cryptoJs.enc.Hex.parse(message),
    },
    keyHex,
    {
      mode: cryptoJs.mode.ECB,
      padding: cryptoJs.pad.Pkcs7,
    }
  );
  return decrypted.toString(cryptoJs.enc.Utf8);
}
