/**
 * 页面是否在微信打开
 */

export function is_weixin_visit() {
  var ua = navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) == "micromessenger") {
    return true;
  } else {
    return false;
  }
}

/**
 * 页面是否在微信小程序打开
 */
export function isWxMini() {
  var ua = navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) == "micromessenger") {
    return new Promise((resolve) => {
      window.wx.miniProgram.getEnv((res) => {
        if (res.miniprogram) {
          resolve("mini-wx");
        } else {
          resolve("wx");
        }
      });
    });
  } else {
    console.log("不在微信里");
    return new Promise((resolve) => {
      resolve("no-wx");
    });
  }
}
