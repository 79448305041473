import axios from "axios";
import qs from "qs";
// 创建 axios 实例
let service = axios.create({
  timeout: 60000,
  withCredentials: false,
});

// 设置 post、put 默认 Content-Type
service.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";
service.defaults.headers.put["Content-Type"] =
  "application/x-www-form-urlencoded";

// 添加请求拦截器
service.interceptors.request.use(
  (config) => {
    if (
      config.method === "post" ||
      config.method === "get" ||
      config.method === "put"
    ) {
      // post、put 提交时，将对象转换为string, 为处理Java后台解析问题
      if (!config.headers["Content-Type"]) {
        //未自己添加header的处理
        config.headers["Content-Type"] =
          "application/x-www-form-urlencoded;charset=UTF-8";
        config.data = qs.stringify(config.data); //post和get都会拼接参数，参数成一个
      }
    }
    return config;
  },
  (error) => {
    // 请求错误处理
    return Promise.reject(error);
  }
);

// 添加响应拦截器
service.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    let info = {},
      { status, statusText, data } = error.response || {};

    if (!error.response) {
      info = {
        code: 5000,
        msg: "Network Error",
      };
    } else {
      // 此处整理错误信息格式
      info = {
        code: status,
        data: data,
        msg: statusText,
      };
    }
    console.log("error info", info);
    return false;
  }
);

/**
 * 创建统一封装过的 axios 实例
 * @return {AxiosInstance}
 */
export default function () {
  return service;
}
