/**
 * 公告相关 （数据为本地数据）
 */
/* eslint-disable no-unused-vars */
var noticeList = [
  {
    id: 1,
    title: "妙手医生医疗门诊部互联网医院诊疗服务全面上线啦！",
    content: `妙手医生医疗门诊部互联网医院诊疗服务于2022年1月4日正式全面上线，为广大居民提供多元化的线上复诊服务。只要在手机上“点一点”，即可足不出户享受到我院专科医生的线上诊疗。
      我院将传统线下门诊搬到线上，患者可随时通过手机进行图文问诊，医生利用碎片时间在线接诊患者，为患者提供线上门诊开立处方、检验、检查、治疗等业务，患者只需要在手机端完成缴费，提供病情资料，即可进行线上诊疗。`,
    time: "2022-1-24",
    questions: [
      {
        question: "Q1：什么是互联网诊疗？",
        detail:
          "互联网诊疗”，即医疗机构利用在本机构注册医师，通过互联网服务等信息技术为广大居民提供多元化的线上复诊服务。凡近期（三个月内）在线下医院就诊过的患者均可在我院互联网医院进行复诊。\n患者在我院互联网医院复诊时，需上传病历资料，医生通过图文给出诊疗意见，根据需要医生可以为患者线上开具处方、检查、检验。",
      },
      {
        question: "Q2：互联网诊疗开诊的时间？",
        detail:
          "周一至周日的8:00-20:00可以发起问诊，医生非实时在线，将在24小时内回复您的问诊咨询，请耐心等待。",
      },
      {
        question: "Q3：在互联网诊疗开具的处方如何取药？",
        detail: "目前仅支持到院自取。",
      },
    ],
  },
  {
    id: 2,
    title: "新冠肺炎疫情防疫小知识",
    content: "",
    time: "2022-1-24",
    questions: [
      {
        question: "1. 什么是冠状病毒？",
        detail:
          "冠状病毒为不分节段的单股正链 RNA病毒，显微镜下病毒包膜上有向四周伸出的突起，形如皇冠而得名。根据血清型和基因组特点冠状病毒亚科被分为α、β、γ、δ四个属。已知感染人的冠状病毒有6种，如我们所知的中东呼吸综合征相关冠状病毒（MERS-CoV）和严重急性呼吸综合征相关冠状病毒（SARS-CoV）等。",
      },
      {
        question: "2. 什么是新型冠状病毒？",
        detail:
          "这是一种由新型冠状病毒引发的肺部炎症。2020 年 1 月 12 日，世界卫生组织正式将造成此次肺炎疫情的病毒命名为“2019新型冠状病毒”（2019-novel coronavirus，2019nCoV），简称新型冠状病毒。2020年2月11日，世界卫生组织宣布，将新型冠状病毒引发的疾病正式命名为：2019冠状病毒病（Corona virusdisease2019，COVID-19）根据既往所知，能感染人类的冠状病毒共有6种，它们是——α属：人冠状病毒229E、人冠状病毒NL63；β属：OC43、IIKU1、严重急性呼吸综合征相关病毒（SARSr-CoV）、中东呼吸综合征相关病毒（MERSI-COV）。2019-nCoV是目前已知的第7种能感染人的冠状病毒，属于β属。",
      },
      {
        question: "3. 对新冠疫情防控的总体要求有哪些？",
        detail:
          "坚持“预防为主、防治结合、依法科学、分级分类”的原则；按照“及时发现、快速处置、精准管控、有效救治”的工作要求；落实“早发现、早报告、早隔离、早治疗”措施。",
      },
      {
        question: "4. 什么时候需要戴口罩？",
        detail:
          "疫情期间，与人碰面、到公共场所、进入人员密集或密闭场所、乘坐公共交通工具等时，均建议戴口罩。独处或在空旷场所，可以不戴口罩。",
      },
      {
        question: "5. 如何正确使用口罩？",
        detail:
          "一次性医用口罩 / 医用外科口罩的正确使用方法如下：鼻夹朝上，外层深色面朝外（或褶皱朝下）。上下拉开褶皱，将口罩覆盖口、鼻、下颌。将双手指尖沿着鼻梁金属条，由中间至两边，慢慢向内按压，直至紧贴鼻梁。适当调整口罩，使口罩周围充分贴合面部。标准的外科口罩分3层：外层有阻水层，可防止飞沫进入口罩；中层有过滤层；近口鼻的内层用于吸湿。",
      },
      {
        question: "6. 如何丢弃使用过的口罩？",
        detail:
          "普通人群佩戴过的口罩，没有新型冠状病毒传播的风险，使用后装入塑料袋密封，按照生活垃圾分类的要求处理。疑似病人及其护理人员用过的口罩，按照医疗废物收集、处理，处理完口罩后要清洗双手。",
      },
      {
        question: "7. 应该戴哪种口罩？",
        detail:
          "对于一般公众（医务工作者或疫情相关工作人员除外），建议戴一次性医用口罩。人员密集场所的工作人员（医院、机场、火车站、地铁、地面公交、飞机、火车、超市、餐厅等）和警察、保安、快递等从业人员，以及居家隔离及与其共同生活人员，建议佩戴医用外科口罩，或者佩戴符合 N95/KN95 及以上标准的颗粒物防护口罩。不推荐使用纸口罩、活性炭口罩、棉纱口罩和海绵口罩。",
      },
      {
        question: "8. 参加会议需要佩戴口罩吗？",
        detail: `
          ● 建议始终佩戴口罩。<br/>
          ● 谈话保持适度距离。<br/>
          ● 多开窗通风。<br/>
          ● 减少集中开会次数。<br/>
          ● 控制会议时间。`,
      },
      {
        question: "9. 日常生活中是否需要戴手套？",
        detail:
          "不需要，一般公众日常生活中勤洗手就可以了。医护人员、密切接触者、乘务员等在人流密集场所工作者，应戴手套，以减少接触传播的风险。但是戴手套不能代替手卫生，还是要勤洗手。",
      },
      {
        question: "10. 日常生活中是否需要佩戴护目镜？",
        detail:
          "不需要。一般生活及工作中，做好手卫生就足够了，如勤洗手，特别是不用脏手揉眼睛，不需要佩戴护目镜。",
      },
    ],
  },
  {
    id: 3,
    title: "诊疗准则内容",
    content: "",
    time: "2022-1-24",
    questions: [
      {
        question: "一、医疗行为准则",
        detail: `
        ● 以病人为中心，医患合作，“一对一”平等交流；<br/>
        ● 以主诊为中心，首诊负责，各科配合，流程运作，规范医疗；<br/>
        ● 仁爱行医，诚信待人，真诚服务，体贴关爱。<br/>
        ● 交流“五不”：与患者交谈中，不讲不文明的生硬话、不讲与病无关的题外话、不讲不留余地的过头话、不讲不顾后果的刺激话、不讲不负责任的议论话。<br/>
        ● 三个“留意”：留意患者及亲属的情绪变化、留意他们对疾病认识和治疗的期望、留意自我情绪控制和态度。<br/>
        ● “六心”服务：听主诉及解释时要耐心；观察、检查时要细心；语言上要贴心；主动帮忙要热心；询问时要有爱心；行为要表达责任心。`,
      },
      {
        question: "二、医疗责任和义务",
        detail: `
          1、责任：所有医护人员在医疗服务过程中，均负有医疗质量安全责任；负有医院文化传播、医疗服务宣传、品牌形象维护与推广责任；负有维护医院及社会大局利益和患者合法权益之责任。

          2、义务：所有医护人员在医疗服务过程中必须对患者履行“观察、注意、告知”等法律义务，为患者提供满意的人文关怀服务。其中:<br/>
          ● 观察：认真观察患者生命体征和病情变化；详细记录病情变化与病历，及时分析处置。<br/>
          ● 注意：医师不能只限于常规项目检查，同时必须注意患者特殊症状、体征的专项检查。不能仅仅只听主诉，只限于单病种诊断，要提高对疾病鉴别诊断水平。<br/>
          ● 告知：常规检查、治疗、手术预先告知；病情变化、特殊处置、治疗方式预先告知；危重病人抢救治疗要事先告知患者家属确认、签字。`,
      },
      {
        question: "Q3：在互联网诊疗开具的处方如何取药？",
        detail: "目前仅支持到院自取。",
      },
    ],
  },
];

export default {
  namespaced: true,
  state: {
    list: noticeList,
  },
  getters: {},
  mutations: {},
};
