const devConfig = {
  appid: "8FDCAE65A13ABB77",
  appkey: "CC84435906F21F5A6C6C2BBB3599589A",
  os: "h5",
  version: "5.7.6",
  des_key: "miaoshou2021_04.@yuanxin.com",
  channel_key: "14713B407764C277",
};

const prodConfig = {
  appid: "6F51A98981602A7E",
  appkey: "CC84435906F21F5A6C6C2BBB3599589A",
  os: "h5",
  version: "5.7.6",
  des_key: "miaoshou2021_04.@yuanxin.com",
  channel_key: "FF76E66F22E100DA",
};
let config = process.env.VUE_APP_IS_DEV == 1 ? devConfig : prodConfig;
export default config;

// export const newImApiConfig = {
//   appid: "1000000102",
//   appkey: "BB4DA86B809D5BFEAFE98B82F3B7EC42",
// };

const devIMApiConfig = {
  appid: "100001",
  channel_key: "B383DAB01EBC0989",
  des_key: "miaoshou2022_02.@yuanxin.com",
  appkey: "BB4DA86B809D5BFEAFE98B82F3B7EC42",
};

const prodIMApiConfig = {
  appid: "1000002",
  channel_key: "B383DAB01EBC0989",
  des_key: "miaoshou2022_02.@yuanxin.com",
  appkey: "BB4DA86B809D5BFEAFE98B82F3B7EC42",
};

export const newImApiConfig =
  process.env.VUE_APP_IS_DEV == 1 ? devIMApiConfig : prodIMApiConfig;
//互联网医院id 1银川 2广州 3海南 4四川 5天津 6深圳
export const hospital_id = 2;
