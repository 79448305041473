import index from "@/base/index";
import indexAES from "@/base/indexAES";

const install = function (Vue) {
  if (install.installed) return;

  install.installed = true;
  Object.defineProperties(Vue.prototype, {
    $api: {
      get() {
        return index;
      },
    },
    $ase: {
      get() {
        return indexAES;
      },
    },
  });
};

export default {
  install,
};
