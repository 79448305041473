// import tim from 'tim'
import TIM from "tim-js-sdk-ws";

const tim = TIM.create({
  // eslint-disable-next-line no-undef
  SDKAppID: ENV.sdkAppID,
});

window.setLogLevel = tim.setLogLevel;

// 无日志级别
tim.setLogLevel(1);
export default tim;
