// 按需全局引入 vant组件
import Vue from "vue";
import {
  Field,
  CellGroup,
  Form,
  Rate,
  Popup,
  CountDown,
  Uploader,
  DropdownMenu,
  DropdownItem,
  Divider,
  PullRefresh,
  Grid,
  GridItem,
} from "vant";

//按 a~z 顺序来
Vue.use(Field)
  .use(CellGroup)
  .use(CountDown)
  .use(Divider)
  .use(DropdownItem)
  .use(DropdownMenu)
  .use(Form)
  .use(Rate)
  .use(Popup)
  .use(PullRefresh)
  .use(GridItem)
  .use(Grid)
  .use(Uploader);
