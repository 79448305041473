// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/_css-loader@3.6.0@css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../node_modules/_css-loader@3.6.0@css-loader/dist/cjs.js??ref--9-oneOf-3-1!../../../node_modules/_postcss-loader@3.0.0@postcss-loader/src/index.js??ref--9-oneOf-3-2!../../../node_modules/_sass-loader@8.0.2@sass-loader/dist/cjs.js??ref--9-oneOf-3-3!./normalize.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../../node_modules/_css-loader@3.6.0@css-loader/dist/cjs.js??ref--9-oneOf-3-1!../../../node_modules/_postcss-loader@3.0.0@postcss-loader/src/index.js??ref--9-oneOf-3-2!../../../node_modules/_sass-loader@8.0.2@sass-loader/dist/cjs.js??ref--9-oneOf-3-3!./base_xww.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
exports.push([module.id, "html,\nbody {\n  background: #f5f5f5; }\n\nhtml {\n  height: 100%; }\n\nbody > :first-child {\n  height: 100%;\n  overflow: auto;\n  box-sizing: border-box; }\n\n* {\n  padding: 0px;\n  margin: 0px;\n  word-break: break-all;\n  word-wrap: break-word;\n  -webkit-tap-highlight-color: transparent;\n  outline: 0; }\n\nol,\nul,\nli {\n  list-style: none; }\n\na {\n  color: #333;\n  outline: none;\n  text-decoration: none;\n  background: none; }\n\nh1,\nh2,\nh3,\nh4,\nh5,\nh6 {\n  font-size: 100%;\n  font-weight: normal;\n  line-height: inherit; }\n\n::-webkit-input-placeholder {\n  /* WebKit, Blink, Edge */\n  color: #999; }\n\n:-moz-placeholder {\n  /* Mozilla Firefox 4 to 18 */\n  color: #999;\n  opacity: 1; }\n\n::-moz-placeholder {\n  /* Mozilla Firefox 19+ */\n  color: #999;\n  opacity: 1; }\n\n:-ms-input-placeholder {\n  /* Internet Explorer 10-11 */\n  color: #999; }\n\n::-ms-input-placeholder {\n  /* Microsoft Edge */\n  color: #999; }\n", ""]);
// Exports
module.exports = exports;
