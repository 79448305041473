/**
 * 添加cookie
 * @param {String} objName
 * @param {String} objValue
 * @param {Number} objHours
 */
// export function addCookie(objName, objValue, objHours) {
//   var str = objName + "=" + encodeURIComponent(objValue);

//   if (objHours > 0) {
//     // 如果不设定过期时间, 浏览器关闭时cookie会自动消失
//     var date = new Date();
//     var ms = objHours * 3600 * 1000;

//     date.setTime(date.getTime() + ms);
//     str += "; expires=" + date.toGMTString() + "; path=/;"; // 指定了cookie的path
//   }

//   document.cookie = str;
//   return str;
// }

export function addCookie(sKey, sValue, sHours, sPath, sDomain, bSecure) {
  if (!sKey || /^(?:expires|path|domain|secure)$/i.test(sKey)) {
    return false;
  }
  var sExpires = "";
  let date = new Date();
  let ms = sHours * 3600 * 1000;
  let vEnd = date.setTime(date.getTime() + ms);

  if (vEnd) {
    switch (vEnd.constructor) {
      case Number:
        sExpires =
          vEnd === Infinity
            ? "; expires=Fri, 31 Dec 9999 23:59:59 GMT"
            : "; max-age=" + ms / 1000;
        break;
      case String:
        sExpires = "; expires=" + vEnd;
        break;
      case Date:
        sExpires = "; expires=" + vEnd.toUTCString();
        break;
    }
  }
  document.cookie =
    encodeURIComponent(sKey) +
    "=" +
    encodeURIComponent(sValue) +
    sExpires +
    "; SameSite=Lax" +
    (sDomain ? "; domain=" + sDomain : "") +
    // (sPath ? "; path=" + sPath : "") +
    "; path=/" +
    (bSecure ? "; secure" : "");
  return true;
}
