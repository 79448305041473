"use strict";
import { decryptDes, encryptDes } from "@/base/DES.js";
import { signParams, imSignParams } from "@/base/signUtils.js";
import appConfig from "@/appConfig";
import { newImApiConfig } from "@/appConfig.js";

/**
 * 请求基础参数接口
 * 获取access_token && aeskey
 */
async function dataRES(params) {
  var jsonStrNew = JSON.stringify(await signParams(params));
  var dataNew = encodeURIComponent(
    await encryptDes(jsonStrNew, appConfig.des_key)
  );
  var newtempParams = Object.assign({ data: dataNew });
  return {
    newtempParams: newtempParams,
  };
}

async function iMDataRES(params) {
  var jsonStrNew = JSON.stringify(await imSignParams(params));
  var dataNew = encodeURIComponent(
    await encryptDes(jsonStrNew, newImApiConfig.des_key)
  );
  var newtempParams = Object.assign({ data: dataNew });
  return {
    newtempParams: newtempParams,
  };
}

async function getData({ code, data, msg }) {
  try {
    return {
      code,
      data: JSON.parse(await decryptDes(data, appConfig.des_key)),
      msg,
    };
  } catch (e) {
    return {};
  }
}

async function getIMData({ code, data, msg }) {
  try {
    return {
      code,
      data: JSON.parse(await decryptDes(data, newImApiConfig.des_key)),
      msg,
    };
  } catch (e) {
    return {};
  }
}

export { dataRES, getData, getIMData, iMDataRES };
