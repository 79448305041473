export async function loadScript(url) {
  if ("[object Array]" == Object.prototype.toString.call(url)) {
    return await Promise.all(url.map((item) => addScript(item)));
  }
  if ("[object String]" == Object.prototype.toString.call(url)) {
    return await addScript(url);
  }
}

let cacheUrl = {};
let cachePromise = {};
function addScript(url) {
  if (cacheUrl[url] || isScriptExist(url)) {
    if (cachePromise[url]) return cachePromise[url]; //两个接口时，需要等待加载
  }

  cacheUrl[url] = true;
  cachePromise[url] = new Promise(function (resolve, reject) {
    addScriptToHead(
      url,
      function () {
        resolve();
        cacheUrl[url] = false;
      },
      function () {
        cacheUrl[url] = false;
        reject();
      }
    );
  });

  return cachePromise[url];
}

//判断请求的script是否被加载
function isScriptExist(url) {
  var scripts = document.getElementsByTagName("script");
  var scriptArr = Array.prototype.slice.apply(scripts);
  var filterArr = scriptArr.filter((item) => {
    return item.src.indexOf(url) != -1;
  });
  return filterArr.length > 0;
}

export function addScriptToHead(
  url = "",
  onloadFn = () => {},
  onErrorFn = () => {}
) {
  if (!url) {
    console.error("添加script标签报错");
    return;
  }
  var script = document.createElement("script");
  script.charset = "utf-8";
  script.timeout = 120;
  script.src = url;
  script.onload = onloadFn;
  script.onerror = onErrorFn;
  // script.defer = true; //本身已经是异步了
  document.head.appendChild(script);
}
