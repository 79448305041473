//取消环境变量，更改为参数
let herfUrl = window.location.href;

let proxyConfig = {
  api: "/api",
  yskp: "/yskp",
  newBpi: "/new_bpi",
};

if (herfUrl.indexOf("//m.gz-miaoshou.com") != "-1") {
  // let isTest = herfUrl.indexOf("test") != "-1";
  // let baseDomainPrefix = isTest ? "test." : "";
  // let imDomainPrefix = isTest ? "test-" : "";
  proxyConfig = {
    api: `//api-hospitals.yuanxinjituan.cn`,
    yskp: `//bapi.miaoshou.net`,
    newBpi: `//im-service.yuanxinjituan.cn`,
    // api: `//${baseDomainPrefix}api-hospitals.yuanxinjituan.cn`,
    // yskp: `//${baseDomainPrefix}bapi.miaoshou.net`,
    // newBpi: `//${imDomainPrefix}im-service.yuanxinjituan.cn`,
  };
}

export default proxyConfig;
