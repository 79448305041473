/**
 * 问诊流程----1------2
 */
/* eslint-disable no-unused-vars */
import request from "@/base/index";
import { hospital_id } from "@/appConfig.js";

const defaultDiseaseList = [
  { id: 1, name: "先天性心脏病", isCheck: false },
  { id: 2, name: "肿瘤", isCheck: false },
  { id: 3, name: "动脉硬化", isCheck: false },
  { id: 4, name: "脑血管病", isCheck: false },
  { id: 5, name: "冠心病", isCheck: false },
  { id: 6, name: "糖尿病", isCheck: false },
];
const allergyDefaultDiseaseList = [
  { id: 1, name: "动物皮屑", isCheck: false },
  { id: 2, name: "尘螨", isCheck: false },
  { id: 3, name: "灰尘", isCheck: false },
  { id: 4, name: "花粉", isCheck: false },
  { id: 5, name: "鸡蛋", isCheck: false },
  { id: 6, name: "牛奶", isCheck: false },
  { id: 7, name: "鱼、虾、海产品", isCheck: false },
  { id: 8, name: "阿司匹林", isCheck: false },
];
export default {
  namespaced: true,
  state: {
    fileList: [],
    textarea: "",
    isHpi: 0, //值为0,1
    isPmh: 0, //值为0,1
    is_allergic_history: 2, //值为1有、2无
    diseases: [],
    hpiDiseaseList: defaultDiseaseList.map((disease) => ({ ...disease })),
    pmhDiseaseList: defaultDiseaseList.map((disease) => ({ ...disease })),
    allergyDiseaseList: allergyDefaultDiseaseList.map((disease) => ({
      ...disease,
    })),
    hospital: {},
  },
  getters: {},
  mutations: {
    saveFileList(state, paylod) {
      state.fileList.push(paylod);
    },
    resetSecondStep(state) {
      state.textarea = "";
      state.isHpi = 0; //值为0,1
      state.isPmh = 0; //值为0,1
      state.is_allergic_history = 2; //值为1有、2无
      state.hospital = {}; //值为1,2
      state.diseases = [];
      state.hpiDiseaseList = defaultDiseaseList.map((disease) => ({
        ...disease,
      }));
      state.pmhDiseaseList = defaultDiseaseList.map((disease) => ({
        ...disease,
      }));
      state.allergyDiseaseList = allergyDefaultDiseaseList.map((disease) => ({
        ...disease,
      }));
    },
    resetFirstStep(state) {
      state.textarea = "";
      state.fileList = [];
    },
    saveTextarea(state, paylod) {
      state.textarea = paylod;
    },
    //设置 departmentVal、jobVal、sortVal
    setState(state, payload) {
      let { key, value } = payload;
      state[key] = value;
    },
    // 添加疾病
    addDiseases(state, payload) {
      const [item = null] = state.diseases.filter(
        (item) => item.common_icd_id == payload.common_icd_id
      );
      console.log(payload);
      if (!item) {
        state.diseases.push(payload);
      }
    },
    delImg(state, index) {
      state.fileList.splice(index, 1);
    },
  },
  actions: {
    createConsult(context, payload) {
      const state = context.state;
      let diseasesObj = {};
      state.diseases.forEach((item) => {
        diseasesObj[item.code] = item.name;
        // return item.icd_name;
      });
      const files = state.fileList.map((item) => item.url);
      const {
        province_id,
        province_name,
        city_id,
        city_name,
        consult_org_id,
        consult_org,
      } = state.hospital;
      let param = Object.assign({}, payload, {
        province_id,
        province_name,
        city_id,
        city_name,
        consult_org_id,
        consult_org,
        hpi: state.isHpi,
        pmh: state.isPmh,
        is_allergic_history: state.is_allergic_history,
        offline_diagnosis: JSON.stringify(diseasesObj),
        illness_description: state.textarea,
        illness_images: JSON.stringify(files),
        hospital_id,
      });
      console.log("param", param);
      return request
        .post("api/guangzhou-pat/consult/create", param, true)
        .then(({ code, msg, data }) => {
          console.log("consult/create", data);
          return {
            code,
            msg,
            data,
            param,
          };
        });
    },
    resetFirstStep(context) {
      context.commit("resetFirstStep");
    },
    resetSecondStep(context) {
      context.commit("resetSecondStep");
    },
    delImg(context, index) {
      context.commit("delImg", index);
    },
  },
};
