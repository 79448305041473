/*
 * @Author: 梁前勇 929245885@qq.com
 * @Date: 2023-08-23 15:18:22
 * @LastEditors: 梁前勇 929245885@qq.com
 * @Description:
 */
// eslint-disable-next-line no-undef
let test = VUE_APP_IS_DEV === 1 ? "https://" : "//";
export const NodeRSAUrl = `${test}www.miaoshoucdn.com/static/js/NodeRSA.js`;
export const JSEncryptUrl = `${test}www.miaoshoucdn.com/static/js/jsencrypt.min.js`;
export const CryptoJSUrl = `${test}www.miaoshoucdn.com/static/js/crypto.js`;
export const PromisejsUrl = `${test}www.miaoshoucdn.com/static/js/promise.min.js`;
