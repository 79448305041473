/* eslint-disable no-unused-vars */
import request from "@/base/index";
import { hospital_id } from "@/appConfig.js";
import { Toast } from "vant";
const DEFAULT_PAGE = 1;
const DEFAULT_PAGE_SIZE = 10;
export default {
  namespaced: true,
  state: {
    doctorList: [],
    doctorInfo: {},
    listOption: {
      page: DEFAULT_PAGE,
      pagesize: DEFAULT_PAGE_SIZE,
    },
    finished: false,
    loading: false,
    inquiriesWay: null, //问诊方式  1 图文  3 视频
  },
  mutations: {
    saveDoctorList(state, payload) {
      state.doctorList = payload.data;
      state.finished = payload.finished;
    },
    resetData(state) {
      state.listOption = {
        page: DEFAULT_PAGE,
        pagesize: DEFAULT_PAGE_SIZE,
      };
      state.doctorList = [];
      state.finished = false;
      state.loading = false;
    },
    resetListOptins(state) {
      state.listOption = {
        page: DEFAULT_PAGE,
        pagesize: DEFAULT_PAGE_SIZE,
      };
    },
    addPage(state) {
      state.listOption.page += 1;
    },
    changeLoading(state, payload) {
      state.loading = payload;
    },
    saveDoctorInfo(state, payload) {
      state.doctorInfo = payload;
    },
    editInquiriesWay(state, payload) {
      state.inquiriesWay = payload;
    },
  },
  actions: {
    getDoctorList(context, payload = {}) {
      const { doctorList, listOption } = context.state;
      const isLoadMore = !!payload.isLoadMore;
      let pageOptions = null;
      if (isLoadMore) {
        pageOptions = listOption;
      } else {
        pageOptions = { page: DEFAULT_PAGE, pagesize: DEFAULT_PAGE_SIZE };
        context.commit("resetListOptins");
      }

      delete payload.isLoadMore;
      const params = { hospital_id, ...payload, ...pageOptions };
      const toast = Toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: false,
      });
      console.log(params, "搜索参数----------------------");
      return request
        .get("api/guangzhou-pat/doctor/list", params, true)
        .then(({ code, data, msg }) => {
          console.log("doctor/listdoctor/list", params, data);
          if (200 == code) {
            const finished = data.length !== pageOptions.pagesize;

            const appendData = Array.isArray(data) ? data : []; //如果下一页没有更多数据时返回data为false
            const newData = isLoadMore ? doctorList.concat(appendData) : data;
            context.commit("saveDoctorList", { data: newData, finished });
          } else {
            context.commit("showMessage", msg, { root: true });
          }
        })
        .finally(() => {
          toast.clear();
          if (isLoadMore) {
            context.commit("changeLoading", false);
          }
        });
    },
    resetData(context) {
      context.commit("resetData");
    },
    loadMore(context, payload) {
      if (!context.state.finished) {
        context.commit("addPage");
        context.dispatch("getDoctorList", {
          ...payload,
          isLoadMore: true,
        });
      }
    },
    changeLoading(context, payload) {
      context.commit("changeLoading", payload);
    },
    getDoctorInfo(context, payload = {}) {
      const doctor_id = payload.doctor_id;
      const doctorInfo = context.state.doctorInfo;
      //信息中存的是当前医生则不刷新
      if (doctorInfo.doctor_id == doctor_id) {
        return Promise.resolve();
      }
      return new Promise((resolve, reject) => {
        request
          .get(
            "api/guangzhou-pat/doctor/info",
            { ...payload, hospital_id },
            true
          )
          .then(({ code, data, msg }) => {
            if (200 == code) {
              context.commit("saveDoctorInfo", data);
              resolve(data);
            } else {
              context.commit("showMessage", msg, { root: true });
              reject(msg);
            }
          });
      });
    },
    editInquiriesWay(context, payload) {
      context.commit("editInquiriesWay", payload);
    },
  },
  modules: {},
};
