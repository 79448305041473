/**
 * 评价相关
 */
/* eslint-disable no-unused-vars */
import request from "@/base/index";
import { hospital_id } from "@/appConfig.js";
import { isNoEmpty } from "@/utils/validator/isEmptyOrNot";
export default {
  namespaced: true,
  state: {
    info: null,
    isDone: false,
    doctor: null,
  },
  getters: {
    getScoreText(state) {
      let text = "";
      const { score } = state.info;
      if (score > 0 && score <= 2) {
        text = "不满意";
      } else if (score > 2 && score <= 4) {
        text = "满意";
      } else if (score > 4 && score <= 5) {
        text = "很满意";
      }
      return text;
    },
  },
  mutations: {
    saveResult(state, payload) {
      state.info = payload;
      state.isDone = isNoEmpty(payload);
    },
    setDoctor(state, payload) {
      state.doctor = payload;
    },
    resetInfo(state) {
      state.info = null;
      state.doctor = null;
      state.isDone = false;
    },
  },
  actions: {
    getOrSetEvaluateState(context, payload) {
      return request
        .post(
          "api/guangzhou-pat/person/evaluate-info",
          { ...payload, hospital_id },
          true
        )
        .then(({ code, msg, data }) => {
          if (code == 200 || code == 201) {
            let info = data;
            if (code == 200) {
              info = payload;
            } else {
              info.score = parseInt(info.score);
            }
            context.commit("saveResult", info);
          } else {
            context.commit("showMessage", msg, { root: true });
          }
          return {
            code,
          };
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setDoctorInfo(context, payload) {
      context.commit("setDoctor", payload);
    },
    clearInfo(context) {
      context.commit("resetInfo");
    },
  },
};
