/* eslint-disable prettier/prettier */
import axios from "@/base/axios";
import { dataRES, iMDataRES, getData, getIMData } from "@/base/dataRES";
import { signParams ,imSignParams } from "@/base/signUtils.js";
import { getBaseUrl } from "@/base/baseUrl";
let instance = axios();

export default {
  /** *
   * defUrl: 请求地址
   * params: 请求参数
   * flag：是否加密,————默认加密
   */
  get(defUrl, params, flag = true) {
    const url = getBaseUrl(defUrl);
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async function (resolve, reject) {
      const options = {};
      if (!flag) {
        console.log("params",params)
        // 不需要加密
        options.params = await signParams(params);
        resolve(options);
      } else {
        let _params = await dataRES(params);
        if (!_params) {
          reject();
          return;
        }
        options.params = _params.newtempParams;
        resolve(options);
      }
    }).then((options) => {
      return instance.get(url, options).then(async (res) => {
        if (flag) {
          return await getData(res.data, params.appid);
        }
        return res.data;
      });
    });
  },
  /** *
   * defUrl: 请求地址
   * params: 请求参数
   * flag：是否加密,————默认加密
   */
  post(defUrl, params = {}, flag = true) {
    const url = getBaseUrl(defUrl);
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async function (resolve, reject) {
      if (!flag) {
        resolve(await signParams(params));
      } else {
        let _params = await dataRES(params);
        if (!_params) {
          reject();
          return;
        }
        resolve(Object.assign({}, _params.newtempParams));
      }
    }).then((params) => {
      return instance.post(url, params).then(async (res) => {
        if (flag) {
          return await getData(res.data, params.appid);
        }
        return res.data;
      });
    });
  },
  /**
   * 上传文件使用
   * 传token 与 file 数据
   */
  upload(defUrl, params, flag = true) {
    const url = getBaseUrl(defUrl);
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async function (resolve) {
      if (!flag) {
        const { file, ...other } = params;
        const _signParams = await signParams(other);
        let formData = new FormData();
        Object.keys(_signParams).forEach((item) => {
          formData.append(item, _signParams[item]);
        });
        formData.append("file", file);
        resolve(formData);
      } else {
        const { file, ...other } = params;
        var { newtempParams = {} } = await dataRES(other);
        let formData = new FormData();
        Object.keys(newtempParams).forEach((item) => {
          formData.append(item, newtempParams[item]);
        });
        formData.append("file", file);
        resolve(formData);
      }
    }).then((formData) => {
      let options = { headers: { "Content-Type": "multipart/form-data" } };
      return instance.post(url, formData, options).then(async (res) => {
        if (flag) {
          return await getData(res.data, params.appid);
        }
        return res.data;
      });
    });
  },
  //
};
export const imRequest ={
  /** *
   * defUrl: 请求地址
   * params: 请求参数
   * flag：是否加密,————默认加密
   */
  get(defUrl, params, flag = true) {
    const url = getBaseUrl(defUrl);
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async function (resolve, reject) {
      const options = {};
      console.log("请求参数",params)
      if (!flag) {
        console.log("params",params)
        // 不需要加密
        options.params = await imSignParams(params);
        resolve(options);
      } else {
        let _params = await iMDataRES(params);
        if (!_params) {
          reject();
          return;
        }
        options.params = _params.newtempParams;
        resolve(options);
      }
    }).then((options) => {
      return instance.get(url, options).then(async (res) => {
        if (flag) {
          return await getIMData(res.data, params.appid);
        }
        return res.data;
      });
    });
  },
  /** *
   * defUrl: 请求地址
   * params: 请求参数
   * flag：是否加密,————默认加密
   */
  post(defUrl, params = {}, flag = true) {
    const url = getBaseUrl(defUrl);
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async function (resolve, reject) {
      console.log("请求参数",params)
      if (!flag) {
        let result = await imSignParams(params)
        resolve(result);
      } else {
        let _params = await iMDataRES(params);
        if (!_params) {
          reject();
          return;
        }
        resolve(Object.assign({}, _params.newtempParams));
      }
    }).then((params) => {
      console.log(params)
      return instance.post(url, params).then(async (res) => {
        if (flag) {
          return await getIMData(res.data, params.appid);
        }
        return res.data;
      });
    });
  },
  /**
   * 上传文件使用
   * 传token 与 file 数据
   */
  upload(defUrl, params, flag = true) {
    const url = getBaseUrl(defUrl);
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async function (resolve) {
      if (!flag) {
        const { file, ...other } = params;
        const _signParams = await imSignParams(other);
        let formData = new FormData();
        Object.keys(_signParams).forEach((item) => {
          formData.append(item, _signParams[item]);
        });
        formData.append("file", file);
        resolve(formData);
      } else {
        const { file, ...other } = params;
        var { newtempParams = {} } = await dataRES(other);
        let formData = new FormData();
        Object.keys(newtempParams).forEach((item) => {
          formData.append(item, newtempParams[item]);
        });
        formData.append("file", file);
        resolve(formData);
      }
    }).then((formData) => {
      let options = { headers: { "Content-Type": "multipart/form-data" } };
      return instance.post(url, formData, options).then(async (res) => {
        if (flag) {
          return await getData(res.data, params.appid);
        }
        return res.data;
      });
    });
  },
  //
};
