/**
 * 购物车相关
 */
import request from "@/base/index";
import { Dialog } from "vant";
export default {
  namespaced: true,
  state: {
    selectAll: false,
    selectTotal: 0,
    selectAmount: "0",
    cartList: [],
  },
  getters: {},
  mutations: {
    refreshCartData(state, payload) {
      if (payload) {
        state.selectAll =
          payload.select_product_total == payload.all_product_total
            ? true
            : false;
        state.selectTotal = payload.select_product_total;
        state.selectAmount = payload.select_product_amount;
        state.cartList = payload.products;
      } else {
        state.selectAll = false;
        state.selectTotal = 0;
        state.selectAmount = "0";
        state.cartList = [];
      }
    },
  },
  actions: {
    getCartList(context, payload = {}) {
      return request
        .post("api/guangzhou-pat/store/get-shopping-cart", payload, true)
        .then(({ code, data, msg }) => {
          console.log("store/get-shopping-cart", payload, data, msg);
          if (200 == code) {
            context.commit("refreshCartData", data.valid[0]);
          }
        });
    },
    refreshCartData(context, payload) {
      context.commit("refreshCartData", payload);
    },
    editCartData(context, payload = {}) {
      return new Promise((reslove) => {
        request
          .post("api/guangzhou-pat/store/edit-drugs", payload, true)
          .then(({ code, data, msg }) => {
            if (200 == code) {
              context.commit("refreshCartData", data[0]);
              if (payload.del_product) {
                context.commit("showMessage", "删除成功", {
                  root: true,
                });
              }
              reslove(msg);
            } else {
              // context.commit("showMessage", msg, {
              //   root: true,
              // });
              Dialog.alert({
                message: msg,
                confirmButtonText: "确定",
                confirmButtonColor: "#576E95",
              });
            }
          });
      });
    },
  },
};
